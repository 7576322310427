<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มอำเภอ
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label for="secondaryProductCode" class="required form-label col-sm-10"
            >จังหวัด</label
          >
          <div class="col-sm-10">
            <select
              class="form-select"
              v-model="form.provinceId"
              required
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
            >
              <div class="invalid-feedback text-end">กรุณากรอก</div>
              <option v-for="item in provinces" :key="item" :value="item.id">
                {{ item.code }} | 
                {{ item.name }}
              </option>
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductName"
            class="required form-label col-sm-10"
            >อำเภอ</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              name="secondaryProductName"
              id="secondaryProductName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center">
          <label for="selectMainProduct" class="form-label col-sm-10"
            >เลือกกลุ่มสินค้าหลัก</label
          >
          <div class="col-sm-10">
            <select
              class="form-select"
              v-model="form.productGroupId"
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
            >
              <option v-for="item in typeProduct" :key="item" :value="item.id">
                {{ item.code }} | {{ item.name }}
              </option>
            </select>
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import addressApi from "@/api/address/";

export default {
  setup() {
    document.title = "CHOMTHANA | เพิ่มประเภทสินค้า";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    form: {
      code: "",
      name: "",
      selectMainProduct: "",
      status: "1",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    typeProduct: [],
    provinces: [],
  }),

  created() {
    this.getAllprovinces();
    // this.getMainProduct();
  },

  // watch: {
  //   "form.productGroupId"(val) {
  //     console.log({ val });
  //   },
  // },

  methods: {
    async getAllprovinces(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await addressApi.province.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {

        this.provinces = responseData.data;
        // this.tableItems = responseData.data;
        // this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },





    async getMainProduct() {
      let responseData = [];
      try {
        responseData = await addressApi.district.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.typeProduct = responseData.data;
        this.form.productGroupId = responseData.data[0].id;
      }
    },
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          // mainProductGroupId: this.form.productGroupId,
          companyId: parseInt(localStorage.getItem("companyId")),
        };

        createResponse = await addressApi.district.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "เพิ่มสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/config/district/index",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },

    checkFormIsEmpty() {
      // !this.form.code
      //   ? (this.isValid = false)
      //   :
      !this.form.name
        ? (this.isValid = false)
        :!this.form.code
        ? (this.isValid = false)


        : // : !this.form.productGroupId
          // ? (this.isValid = false)
          (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/config/district/index",
        query: {},
      });
    },
  },
};
</script>
